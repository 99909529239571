import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/home/home";
import './scss/global.scss';

const App: React.FC = () => {
    const routes = [
      { path: "/", component: Home },
    ];

    return (
        <>
            <BrowserRouter>
                
                <Routes>
                    {routes.map((route, index) => {
                        return (
                            <Route
                                key={index}
                                path={route.path}
                                element={<route.component />}
                            />
                        );
                    })}
                </Routes>
            </BrowserRouter>
        </>
    );
};

export default App;
