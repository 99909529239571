import React, { useRef, useEffect, useState } from 'react';
import './border.scss';
import { motion } from 'framer-motion';
interface BorderProps {
    color: string;
    top: boolean;
    customClass?: string;
}

const Border: React.FC<BorderProps> = ({ color, top, customClass }) => {
    const borderRef = useRef<HTMLDivElement>(null);
    const width = useState<string>('50%');
    useEffect(() => {
        const handleScroll = () => {
            if (borderRef.current) {
                const { top, bottom } = borderRef.current.getBoundingClientRect();
                const isInView = top >= 0 && bottom <= window.innerHeight;
                if (isInView) {
                    borderRef.current.classList.add('active');
                    window.removeEventListener('scroll', handleScroll);
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            {top ? (
                <motion.div
                    

                    viewport={{ once: true }}
                    ref={borderRef}
                    className={`anim-border ${customClass}`}
                    style={{
                        borderTop: `1px solid ${color}`,
                        height: '2px'
                    }}
                ></motion.div>
            ) : (
                <div
                    ref={borderRef}
                    className="anim-border"
                    style={{
                        borderBottom: `1px solid ${color}`,
                        height: '2px'
                    }}
                >{" "}</div>
            )}
        </>
    );
};

export default Border;